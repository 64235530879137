<template>
  <section class="abschnitt abschnitt--hero">
    <div class="hero">
      <img :srcset="getSrcset(heroImgs)" :src="getSrc(heroImgs)" alt="Therapiezimmer" />
    </div>
    <div class="site-title">
      <h1 class="sophie">Sophie Kari, MSc</h1>
      <p class="nee-name">(née Grimmer)</p>
      <p class="psychotherapie">
        Psychotherapie
        <br />
        für Kinder, Jugendliche und Erwachsene
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AbschnittHero',
  data() {
    return {
      heroImgs: [
        { url: require('@/assets/images/illus/startseite_960.png'), size: 960 },
        { url: require('@/assets/images/illus/startseite_1280.png'), size: 1280 },
        { url: require('@/assets/images/illus/startseite_1920.png'), size: 1920 },
        { url: require('@/assets/images/illus/startseite_3840.png'), size: 3840 },
      ],
    }
  },
  mounted() {
    this.resizeHero()
  },
  methods: {
    resizeHero() {
      const elHtml = document.querySelector('html')
      const abschnittHero = document.querySelector('.abschnitt--hero')

      const actualViewportHeight = elHtml.clientHeight
      const actualViewportWidth = elHtml.clientWidth
      const maxHeight = Math.min(actualViewportHeight, actualViewportWidth * 1.5)

      abschnittHero.style.maxHeight = `${maxHeight}px`
    },
    getSrcset(imgs) {
      return imgs.reduce((acc, res) => acc + res.url + ' ' + res.size + 'w,', '')
    },
    getSrc(imgs) {
      return imgs[0].url
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_variables.scss';
@import '@/assets/scss/_mixins.scss';

.abschnitt--hero {
  position: relative;
  height: 100vh;
  margin: 0 calc(50% - 50vw) 2rem;
  padding: 0;
  background: #f4f4f4;
  overflow: hidden;

  .hero {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;

    img {
      position: absolute;
      width: 150%;
      top: 90%;
      transform: translateY(-75%);

      @include desktop {
        width: 100%;
      }
    }
  }

  .site-title {
    position: absolute;
    width: 100%;
    bottom: calc(30vw + 30%);

    @include desktop {
      bottom: calc(10vw + 23%);
      right: 25%;
      transform: translateX(50%);
    }
  }

  .sophie,
  .nee-name,
  .psychotherapie {
    margin: 0 auto;
    text-align: center;
  }

  .sophie {
    font-size: calc(1.5em + 1.875vw);
    font-weight: $font-weight-title;
  }

  .nee-name {
    margin-top: 0.625em;
    margin-bottom: 2.5em;
    font-size: calc(0.875em + 0.375vw);
    font-weight: $font-weight-text;
    letter-spacing: $tracking-wide;
    font-style: italic;
  }

  .psychotherapie {
    margin-top: 2rem;
    font-size: calc(0.875em + 0.375vw);
    font-weight: $font-weight-text;
    line-height: $leading-small;
    letter-spacing: $tracking-wide;
  }
}
</style>
